import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, CardImg } from "reactstrap";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import api from '../../services/api';

import { ToastContainer, toast } from 'react-toastify';
import loading from "../../assets/images/qgr/loading.gif";

//Import Card
import CardEstados from "./CardEstados";
import { getShops } from "../../store/e-commerce/actions";

class QGRPesquisar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Países", link: "/qgrpesquisar" },
        { title: "Estado", link: "#" },
      ],
      qgrs: [],
      isLoading: false
    };
  }

  async carregarQGR () {
    try {
        let obj = '';
        if (localStorage.getItem("authUser")) {
          obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
          headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        await api.get(`/qgrpesquisar/br/admin`, options)
          .then(({ data })=> {
            this.setState({
              isLoading: false
            });

            this.setState({
              qgrs: data
            });
          });

        
    } catch (error) {
        toast.error("Não foi possivel cadastrar seu QGR. Tente novamente!");
    }
  }

  componentDidMount() {
    this.carregarQGR();
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="QGR's por EstadoS"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
            <div className="row">
              <div className="col-12 col">
                  <div className="card">
                      <div className="card-body">
                          <div className="mb-2 row">
                              <div className="col-md-4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                          {/* <div className="mb-3 row">
                                              <div className="col-md-10"><input id="valorPesquisar" placeholder="Pesquisar" type="text" className="form-control" /></div>
                                          </div> */}
                                          <i className="search-box chat-search-box" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-xl-12">
                                  <div className="table-responsive">
                                      <div className="react-bootstrap-table">
                                          <table className="table table align-middle table-nowrap">
                                              <thead className="thead-light">
                                                  <tr>
                                                      <th tabindex="{0}" aria-label="Titulo sort desc" className="sortable">
                                                          Estado<span className="react-bootstrap-table-sort-order"><span className="caret" /></span>
                                                      </th>
                                                      <th tabindex="{0}" aria-label="tipo sortable" className="sortable">
                                                          Total
                                                          <span className="order">
                                                              <span className="dropdown"><span className="caret" /></span><span className="dropup"><span className="caret" /></span>
                                                          </span>
                                                      </th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                {map(this.state.qgrs, (qgr, key) => (
                                                  <tr key={key}>
                                                      <td>{qgr.estado}</td>
                                                      <td>{qgr.total}</td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

QGRPesquisar.propTypes = {
  shops: PropTypes.array,
  onGetShops: PropTypes.func,
};

const mapStateToProps = ({ Ecommerce }) => ({
  shops: Ecommerce.shops,
});

const mapDispatchToProps = (dispatch) => ({
  onGetShops: () => dispatch(getShops()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QGRPesquisar);
