import React, { Component } from "react";
import { Col, Row, Card, CardBody, CardTitle, CardImg, CardText, Container, Button, CardColumns } from "reactstrap";

import telegram from "../../assets/images/telegram.jpeg";
import youtube from "../../assets/images/youtube.png";
import instagram from "../../assets/images/instagram.png";
import meusqgrs from "../../assets/images/qgr/meusqgrs.png";
import lives from "../../assets/images/qgr/lives.jpg";
import batismo from "../../assets/images/qgr/batismo.jpg";

import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                // { title : "QGR", link : "/" },
                // { title : "Home", link : "/dashboard" },
            ],
            reports : [
                { icon : "ri-stack-line", title : "Number of Sales", value : "1452", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-store-2-line", title : "Sales Revenue", value : "$ 38452", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", title : "Average Price", value : "$ 15.4", rate : "2.4%", desc : "From previous period" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="" breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col lg={6}>
                            <h4 className="my-3">Seja bem vindo.</h4>
                        </Col>
                    </ Row>
                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
