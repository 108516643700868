import axios from 'axios';

const url =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3734'
    : 'https://51k1b7wvwg.execute-api.sa-east-1.amazonaws.com/prod';

const api = axios.create({ baseURL: url });

export default api;
